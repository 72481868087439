import { onBeforeUnmount, ref } from '@vue/composition-api'

export default () => {
  const redirectDirect = ref(false)
  const redirectForced = ref(false)

  const setForceRedirect = val => {
    redirectForced.value = val
  }

  const onKeyUp = ({ key }) => {
    if (!redirectForced.value) {
      redirectDirect.value = !key === 'Shift'
    }
  }

  const onKeyDown = ({ key }) => {
    if (!redirectForced.value) {
      redirectDirect.value = key === 'Shift'
    }
  }

  const registerEvents = _ => {
    window.addEventListener('keyup', onKeyUp)
    window.addEventListener('keydown', onKeyDown)
  }

  const unregisterEvents = _ => {
    window.removeEventListener('keyup', onKeyUp)
    window.removeEventListener('keydown', onKeyDown)
  }

  onBeforeUnmount(() => {
    unregisterEvents()
  }),

  registerEvents()

  return {
    redirectDirect,
    setForceRedirect,
  }
}
