var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.$attrs.label,"vid":_vm.$attrs.vid,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-autocomplete',_vm._g(_vm._b({ref:"autocompleteVm",attrs:{"error-messages":errors,"success":valid,"hide-details":"auto"},scopedSlots:_vm._u([(_vm.customNoData)?{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}:null,(_vm.customAppendItem)?{key:"append-item",fn:function(){return [_vm._t("append-item")]},proxy:true}:null,(_vm.customSelection)?{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_vm._t("selection",null,{"attr":attr,"on":on,"item":item,"selected":selected})]}}:null,(_vm.customItem)?{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}:null,(_vm.customAppendOuter)?{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true}:null],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))}}])})}
var staticRenderFns = []

export { render, staticRenderFns }