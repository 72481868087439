<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
    <v-text-field
      v-model="innerValue"
      slot-scope="{ errors, valid }"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      hide-details="auto"
      v-on="$listeners"
    >
      <template v-if="customPrepend" #prepend>
        <slot name="prepend" />
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'VTextFieldWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    customPrepend: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const innerValue = ref('')

    if (props.value) {
      innerValue.value = props.value
    } else if (props.value === 0) {
      innerValue.value = props.value.toString()
    }

    // Handles internal model changes.
    watch(innerValue, newVal => emit('input', newVal))

    // Handles external model changes.
    watch(
      _ => props.value,
      newVal => (innerValue.value = newVal),
    )

    return {
      innerValue,
    }
  },
})
</script>
